<template>
  <div>
    <ul
      id="myTab"
      class="nav nav-pills product-tab"
      role="tablist"
    >
      <template v-for="(tab, index) in tabs">
        <li
          :key="index"
          class="nav-item"
          :class="{ 'cart-link': tab.isCartItem }"
        >
          <a
            v-if="!tab.isCartItem"
            class="nav-link"
            :class="{ 'active': tab.isActive }"
            data-toggle="tab"
            @click="changeTab( $event, tab)"
            :href="`#${tab.containerName}`"
            role="tab"
            :aria-controls="tab.containerName"
            aria-selected="true"
          >
            {{ tab.title }}
          </a>

          <router-link
            v-else
            to="cart"
            class="nav-link"
            title="My Cart"
          >
            <i class="fas fa-shopping-cart"></i> {{ $t('Cart.MY_CART_TITLE') }}
            <template v-if="cartItemCount > 0">
              ({{ cartItemCount }})
            </template>
          </router-link>
        </li>
      </template>
    </ul>

    <div class="tab-content" id="myTabContent">
      <slot name="tabcontent" />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: "ProductTabs",

  props: {
    tabs: {
      type: Array,
      default: () => []
    }
  },

  computed: {
    ...mapGetters({ cartItemCount: 'getCartItemCount' })
  },
  methods: {
    changeTab( e, tab ){
        e.preventDefault();
        this.$emit('onChangeTab', tab);
    }
  }
}
</script>
